// General Styles
* {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

html,
body {
  font-family: $font-content;
  color: $text-black;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: $font-header;
  color: $text-black;
  margin: .1rem;
}

h1 {
  font-size: 4.8rem;
}

h2 {
  font-size: 3.2rem;
}

h3 {
  font-size: 2.8rem;
}

p {
  font-size: 1.6rem;
  line-height: 1.4;
}

a {
  color: $text-black;
}

// button {
//   max-width: 150px;
//   margin: auto;
//   padding: 12px 20px;
//   box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
//   font-size: 1.7rem;
//   font-weight: 500;
//   cursor: pointer;
//   outline: none;
//   -webkit-appearance: none;

 
//   &:disabled {
//     opacity: 0.5;
//     cursor: not-allowed !important;
//   }
// }

.primary-button {
  border-style: none;
  border-radius: 5px;
  background-color: $inactive-aqua;
  color:$text-white;
  max-width: 250px;
  margin: auto;
  padding: 12px 20px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  font-size: 1.7rem;
  font-weight: 500;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;

  &:active {
    background-color: $primary-aqua;
  }

 
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed !important;
  }
}

.secondary-button {
  background-color: $light-cream;
  border: 2px solid $inactive-aqua;
  border-radius: 5px;
  color: $text-black;
  max-width: 250px;
  margin: auto;
  padding: 12px 20px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  font-size: 1.7rem;
  font-weight: 500;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;

  &:active {
    background-color: $secondary-beige;
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed !important;
  }
}

.delete-button {
  background-color: $delete-red;
  border: 2px solid $primary-aqua;
  border-radius: 5px;
  color: $text-white;
  max-width: 250px;
  margin: auto;
  padding: 12px 20px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  font-size: 1.7rem;
  font-weight: 500;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;

  &:active {
    background-color: $secondary-beige;
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed !important;
  }
}

.size-box {
  height: 75px;
  @include flex-style(row, nowrap, center, center);
}

// App Container

.root {
  width: 100%;
}

.app-container {
  width: 100%;
  @include flex-style(column, nowrap, center, center);

}

// Universal Styles

// Navigation styles

.nav-placeholder {
  width: 100%;
  height: 50px;
  @include flex-style(row, nowrap, center, center);
}

.nav-wrapper{
  position: fixed;
  z-index: 200;
  width: 100%;
  height: 50px;
  @include flex-style(row, nowrap, center, center);
  background-color: $brand-aqua;
  border-bottom: 2px solid $nav-border;
}
.navbar {
  position: fixed;
  top: 0;
  height: 50px;
  width: 100%;
  max-width: 800px;
  padding: 0px 5px;
  background-color: $brand-aqua;
  border-bottom: 2px solid $nav-border;
  @include flex-style(row, nowrap, space-between, center);
  
    .navbar-img {
      img {
        height: 50px;
        padding: 5px;
        float: right;
        display: block;
      }
    }
    .nav-links {
      @media #{$responsive-phone} {
        display: none;
      }
      a {
        @include nav-link-style;
      }
    }
    .nav-links-mobile {
      display: none;

      @media #{$responsive-phone} {
        display: flex;
      }

      img {
        height: 24px;
        position: absolute;
        right: 0;
        top: 0;
        margin: 13px;
      }

      .mobile-links-hidden {
        display: none;
      }

      .mobile-links-displayed {
        display: block;
        position: absolute;
        right: 0;
        top: 50px;
        background-color: $brand-aqua;

        a {
          @include mobile-link-style;
        }
      }
    }
}

// Content styles

.content {
  width: 100%;
  max-width: 800px;
  @include flex-style(column, nowrap, space-between, center);
}

.result {
  width: 100%;
}

.saved {
  width: 100%;
}

.splash-page {
  width: 100%;
  // @include flex-style(column, nowrap, space-between, center);
}

// Footer styles

.footer-placeholder {
  width: 100%;
  height: 50px;
  @include flex-style(row, nowrap, center, center);
}

.footer-wrapper {
  position: fixed;
  bottom: 0;
  background-color: $brand-aqua;
  border-top: 2px solid $nav-border;
  width: 100%;
  height: 50px;
  z-index: 200;
  @include flex-style(row, nowrap, center, center);
}
.footer {
  
  width: 100%;
  z-index: 50;
  max-width: 800px;
  color: $text-white;
  padding: 0px 10px;
  @include flex-style(row, nowrap, space-between, center);
    .footer-links {
      a {
        @include footer-link-style;
        margin: 0px 5px;
      }
    }
}

