// Imports

// -- Reset
@import './assets/normalize.css';

// -- Fonts
@import url('https://fonts.googleapis.com/css?family=Lato|Merriweather&display=swap');

// -- Variables

$light-cream: #FFFEFA;  // text on dark background
$off-black: #2E2F38; // text on light background

$brand-aqua: #124950; // navbar and foot background color
$primary-aqua: #6CCAC6; // active buttons
$inactive-aqua: #092C39; // inactive buttons
$warning-yellow: #B29205; // warning buttons
$delete-red: #931210; // delete buttons
$secondary-beige: #B59883; // secondary buttons
$input-field: #EFEFEF; // off-color for form fields
$field-border: #757065; // dark color for form field border
$star-yellow: #FCC400; // bright yellow for saved items

$font-header: 'Lato', sans-serif;
$font-content: 'Merriweather', serif;

$text-black: $off-black;
$text-white: $light-cream;
$nav-border: $inactive-aqua;

$responsive-phone: "only screen and (max-width : 480px)";


// -- Mixins

@mixin flex-style($direction, $wrap, $justify, $align) {
  display: flex;
  flex-direction: $direction;
  flex-wrap: $wrap;
  justify-content: $justify;
  align-items: $align;
}
@mixin mobile-link-style {
  color: $text-white;
  text-decoration: none;
  font-size: 1.6rem;

  position: relative;
  float: none;
  display: block;
  text-align: left;
  margin: 14px 16px;
}

@mixin nav-link-style {
  color: $text-white;
  text-decoration: none;
  font-size: 1.6rem;
  position: relative;

  float: left;
  display: block;
  text-align: center;
  margin: 14px 16px;

  @include animate-link
}

@mixin footer-link-style {
  color: $text-white;
  text-decoration: none;
  font-size: 1.0rem;
  position: relative;

  @include animate-link
}

@mixin animate-link {
  &:hover {
    color: $text-white;
  }

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -4px;
    left: 0;
    background-color: #FFF;
    visibility: hidden;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
  }
  &:hover:before {
    visibility: visible;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

// -- Global Styles
@import './assets/global.scss';

// -- Splash Page Styles

@import './assets/splash.scss';

// -- Form Styles

@import './assets/forms.scss';

// -- Card Styles

@import './assets/cards.scss';

.red-outline {
  border: 1px solid red;
}


// -- Saved Styles
@import './assets/saved.scss';