// Card Styles
.result-card {
  width: 85%;
  max-width: 600px;
  margin:  25px auto;
  padding: 32px;
  background-color: $inactive-aqua;
  color: $text-white;
  text-align: center;
  box-shadow: 2px 2px 10px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  @include flex-style(column, nowrap, space-between, center);

    h1, h2, h3, h4, h5 {
    font-family: $font-header;
    color: $text-white;
    margin: 15px 0px;

    
  }
  .underline {
    text-decoration: underline;
  }
  
  button {
    max-width: 150px;
    margin: 16px 0;
    padding: 12px 20px;
    border-style: none;
    border-radius: 5px;
    background-color: $primary-aqua;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
    font-size: 1.7rem;
    font-weight: 500;
    color: $text-black;
    cursor: pointer;
    outline: none;
    -webkit-appearance: none;

    &:active {
      background-color: $brand-aqua;
    }
    &:disabled {
      opacity: 0.5;
      cursor: not-allowed !important;
    }
  }
}


.card-container {
  width: 85%;
  max-width: 600px;
  margin:  100px auto;
  padding: 32px;
  font-weight: bold;
  background-color: $light-cream;
  box-shadow: 2px 2px 10px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;

  button {
    max-width: 150px;
    margin: 32px 0;
    padding: 12px 20px;
    border-style: none;
    border-radius: 5px;
    background-color: $inactive-aqua;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
    font-size: 1.7rem;
    font-weight: 500;
    color: #fff;
    cursor: pointer;
    outline: none;
    -webkit-appearance: none;

    &:active {
      background-color: $primary-aqua;
    }
    &:disabled {
      opacity: 0.5;
      cursor: not-allowed !important;
    }
  }
  
  
}


.about-container {
  width: 100%;
  margin: 3% 0;
  
  .description-container {
    width:  96%;
    margin: auto;
    // border: 1px solid green;
  }

  .bio-card {
    // background-color: $brand-aqua;
    @include flex-style(row, wrap, space-around, center);
  
    // border: 1px solid red;
    width: 100%;
  
    .about-card {
      width: 100%;
      position: relative;
      max-width: 200px;
      margin:1%;
      -webkit-filter: drop-shadow(0px 10px 5px rgba(0, 0, 0, 0.25));
            filter: drop-shadow(0px 7px 5px rgba(0, 0, 0, 0.25));
      // border: 1px solid dodgerblue;
    
      .bio-pic {
        border-radius: 10px;
      }
      
      .bio-data {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
      
        top: 65%;
        width: 100%;
      
        
        
      
        h1 {
          color: $brand-aqua;
          font-size: 2rem;
        }
      
        p {
          color: $brand-aqua;
          font-size: 1.5rem;
          padding-right:3%;
          
        }
      
        a {
          color: blue;
      
      
          
        }
      }
      
      .git-pic {
        width: 75px;
        background-color: $text-black;
        border-radius: 10px;
        margin: 10px auto;
      }      
    }
  }

}


